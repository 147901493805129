import type { Address, LineItem, Order } from '@commercelayer/sdk';

import type { AddressFieldTypes } from 'checkout/types/address';
import type { PaymentMethod } from 'shared/infra/commerceLayer/types';
import { retrieveGlobalLocale } from 'shared/services/OrderInformationService.globals';
import type { AddressPayload } from 'shared/store/address/types';
import type { OrderDetails, OrderLineItem } from 'shared/store/order/types';
import type { PaymentState } from 'shared/store/payment/types';
import { createSKUCode } from 'shared/types/ids';
import type { Maybe, RequiredNonNull } from 'types/util';
import { formatCurrencyWithLocale } from 'utils/currency';

import type { LOCALE_CODE } from '../contentful/contentful';

const COUPON_PADDING_CHARACTER = '#';
// coupon code anti-corruption
/*
 * Limitation by CommerceLayer - the length of the coupon code has to be 8 characters
 * use case: migrated promotions that have the length less than 8 are padded by '#' signs (newly created ones are validated on the CommerceLayer dashboard during creation)
 * to order => we have to attach the coupon code already padded (as that is how it is defined in CommerceLayer) in order for the promotion to be applied --> 'AIR' -> 'AIR#####'
 * from order <= we have to remove the padding (the string coming back would be the applied promotion) because the promotions are not exposed having this limitation in mind --> 'AIR#####' -> 'AIR'
 */
export const processCouponCodeToOrder = (couponCode: string): string =>
  couponCode === ''
    ? '' // no padding
    : couponCode?.padEnd(8, COUPON_PADDING_CHARACTER).toUpperCase().trim();

export const processCouponCodeFromOrder = (
  couponCode?: Maybe<string>,
): string =>
  couponCode?.split(COUPON_PADDING_CHARACTER).join('').toUpperCase() || '';

export const translateOrderToStore = (
  order: Order,
  locale: LOCALE_CODE = retrieveGlobalLocale(),
): OrderDetails => {
  const {
    id,
    number,
    status,
    total_amount_float: totalAmountFloat,
    subtotal_amount_float: subtotalAmount,
    total_tax_amount_float: totalTaxAmountFloat,
    total_amount_with_taxes_float: totalAmountWithTaxes,
    discount_amount_float: discountAmount,
    discount_amount_cents: discountAmountCents,
    coupon_code: couponCode,
    tax_rate: taxRate,
    total_amount_with_taxes_cents: totalAmountWithTaxesCents,
    total_amount_with_taxes_float: totalAmountWithTaxesFloat,
    currency_code: currencyCode,
    customer_email: customerEmail,
    metadata = {},
  } = order;

  return {
    id,
    number: number ?? null,
    status,
    formattedTotalAmount: formatCurrencyWithLocale(totalAmountFloat, locale),
    formattedSubtotalAmount: formatCurrencyWithLocale(subtotalAmount, locale),
    formattedTotalTaxAmount: formatCurrencyWithLocale(
      totalTaxAmountFloat,
      locale,
    ),
    formattedTotalAmountWithTaxes: formatCurrencyWithLocale(
      totalAmountWithTaxes,
      locale,
    ),
    formattedDiscountAmount: formatCurrencyWithLocale(discountAmount, locale),
    discountAmountCents: discountAmountCents ?? null,
    couponCode: processCouponCodeFromOrder(couponCode),
    taxRate: taxRate ?? null,
    totalAmountFloat: totalAmountFloat ?? null,
    totalAmountWithTaxesCents: totalAmountWithTaxesCents ?? null,
    totalAmountWithTaxesFloat: totalAmountWithTaxesFloat ?? null,
    currencyCode: currencyCode ?? null,
    customerEmail: customerEmail ?? null,
    metadata,
  };
};
/**
 * TODO: when implementing promotions, refactor this function
 * to retrieve the discountCents for the product in the order.
 * The LineItemCollection type doesn't have the property
 * "discountCents", but somehow this is returned for each product
 * when getting the line items of a specific order 🤔
 */
const discountCents = 0;
export const translateProductToStore = (lineItem: LineItem): OrderLineItem => {
  const {
    id,
    sku_code: skuCode,
    bundle_code: bundleCode,
    formatted_unit_amount: formattedUnitAmount,
    formatted_total_amount: formattedTotalAmount,
    quantity,
    unit_amount_cents: unitAmountCents,
    unit_amount_float: unitAmountFloat,
    discount_float: discountFloat,
    total_amount_float: totalAmountFloat,
  } = lineItem;

  return {
    id,
    code: createSKUCode((skuCode || bundleCode) ?? ''),
    formattedUnitAmount: formattedUnitAmount ?? '',
    formattedTotalAmount: formattedTotalAmount ?? '',
    quantity,
    // TODO: refactor it to get from CLayer.
    discountCents,
    discountRate: unitAmountCents ? discountCents / unitAmountCents : 0,
    unitAmountFloat: unitAmountFloat ?? null,
    // using the same type for a product in catalog and product in cart
    amountFloat: unitAmountFloat ?? null,
    discountFloat: discountFloat ?? null,
    totalAmountFloat,
  };
};

export const translateAddressToStore = (
  address: Address,
  type: AddressFieldTypes,
): AddressPayload => ({
  id: address.id,
  type,
  firstName: address.first_name ?? '',
  lastName: address.last_name ?? '',
  companyName: address.company ?? '',
  phoneNumber: address.phone,
  addressLine1: address.line_1,
  addressLine2: address.line_2 ?? '',
  city: address.city,
  zipCode: address.zip_code ?? '',
  state: address.state_code,
  country: address.country_code,
  metadata: address.metadata ?? {},
});

export const translatePaymentMethodToStore = (
  order: RequiredNonNull<Order, 'payment_method'>,
): Pick<PaymentState, 'id' | 'reference'> => ({
  id: order.payment_method.id,
  reference: order.payment_method.reference as PaymentMethod,
});
