import { BLOCKS } from '@contentful/rich-text-types';

import type { ProductSelectorDetails } from 'productSelection/types/products';

const productSelectorMaster: ProductSelectorDetails = {
  name: 'Your own bank account',
  title: 'Your own bank account',
  description: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'Card reader fee',
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.PARAGRAPH,
      },
    ],
    nodeType: BLOCKS.DOCUMENT,
  },
  productBenefits: [
    {
      name: 'Free current account',
      title: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Free current account',
                nodeType: 'text',
              },
            ],
            nodeType: BLOCKS.PARAGRAPH,
          },
        ],
        nodeType: BLOCKS.DOCUMENT,
      },
      icon: {
        imageSrc:
          'https://images.ctfassets.net/lj0z7qfa1yi0/7LyYjXnYFENqqjgDGW6JrK/f92dc1ac888f1cd29e2ab39957ffb195/Vector.svg',
        imageAltText: 'Free current account',
      },
    },
    {
      name: 'Free business Mastercard',
      title: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Free business Mastercard',
                nodeType: 'text',
              },
            ],
            nodeType: BLOCKS.PARAGRAPH,
          },
        ],
        nodeType: BLOCKS.DOCUMENT,
      },
      icon: {
        imageSrc:
          'https://images.ctfassets.net/lj0z7qfa1yi0/7LyYjXnYFENqqjgDGW6JrK/f92dc1ac888f1cd29e2ab39957ffb195/Vector.svg',
        imageAltText: 'Free business Mastercard',
      },
    },
    {
      name: 'Access to sales the next working day',
      title: {
        nodeType: BLOCKS.DOCUMENT,
        data: {},
        content: [
          {
            nodeType: BLOCKS.PARAGRAPH,
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Access to sales the ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'text',
                value: 'next working day',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                data: {},
              },
            ],
          },
        ],
      },
      icon: {
        imageSrc:
          'https://images.ctfassets.net/lj0z7qfa1yi0/2VrCWYKZt4lUqjMzWACrS9/51b02504d8263c0a67dc423a2ae3206a/Vector.svg',
        imageAltText: 'Access to sales the next working day',
      },
    },
  ],
};

const productSelectorStaging: ProductSelectorDetails = {
  name: 'Your own bank account',
  title: 'Your own bank account',
  description: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'Card reader fee',
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.PARAGRAPH,
      },
    ],
    nodeType: BLOCKS.DOCUMENT,
  },
  productBenefits: [
    {
      name: 'Free current account',
      title: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Free current account',
                nodeType: 'text',
              },
            ],
            nodeType: BLOCKS.PARAGRAPH,
          },
        ],
        nodeType: BLOCKS.DOCUMENT,
      },
      icon: {
        imageSrc:
          'https://images.ctfassets.net/lj0z7qfa1yi0/8fyFPV9FpcLxYybpA27A9/080deea346c7bf1cfbc5435600365f24/Vector.svg',
        imageAltText: 'Free current account',
      },
    },
    {
      name: 'Free business Mastercard',
      title: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value: 'Free business Mastercard',
                nodeType: 'text',
              },
            ],
            nodeType: BLOCKS.PARAGRAPH,
          },
        ],
        nodeType: BLOCKS.DOCUMENT,
      },
      icon: {
        imageSrc:
          'https://images.ctfassets.net/lj0z7qfa1yi0/8fyFPV9FpcLxYybpA27A9/080deea346c7bf1cfbc5435600365f24/Vector.svg',
        imageAltText: 'Free business Mastercard',
      },
    },
    {
      name: 'Access to sales in 2-3 business days',
      title: {
        nodeType: BLOCKS.DOCUMENT,
        data: {},
        content: [
          {
            nodeType: BLOCKS.PARAGRAPH,
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'Access to sales in ',
                marks: [],
                data: {},
              },
              {
                nodeType: 'text',
                value: '2-3 business days',
                marks: [
                  {
                    type: 'bold',
                  },
                ],
                data: {},
              },
            ],
          },
        ],
      },
      icon: {
        imageSrc:
          'https://images.ctfassets.net/lj0z7qfa1yi0/6fEGM5Nr2Ki2VKx3x4K037/37e4913a907d33bfb2302be1ceec00ab/Vector.svg',
        imageAltText: 'Access to sales in 2-3 business days',
      },
    },
  ],
};

export const productSelector = (): ProductSelectorDetails => {
  const IS_CF_MASTER = process.env.CONTENTFUL_ENVIRONMENT === 'master';

  if (IS_CF_MASTER) {
    return productSelectorMaster;
  }

  return productSelectorStaging;
};
