import { BLOCKS } from '@contentful/rich-text-types';

import type { UpsellProduct } from 'productSelection/types/products';
import { createCFProductId, createSKUCode } from 'shared/types/ids';

const upsellProductMaster: UpsellProduct = {
  id: createCFProductId('1oeocOwak0b3EMTDxz6wj6'),
  lineItemType: 'sku',
  code: createSKUCode('BA2023001'),
  name: 'Business Account - BA2023001',
  title: 'Business Account',
  trackingId: 'offer.business_account',
  shortDescription: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'Select your preferred payment account',
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.HEADING_2,
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              "By selecting a free Business Account, you'll get a 12% discount on your card reader fee, which you can extend for months just by using your SumUp Card.",
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.PARAGRAPH,
      },
    ],
    nodeType: BLOCKS.DOCUMENT,
  },
  descriptionCTA: 'See discount details',
  description: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [
              {
                type: 'bold',
              },
            ],
            value: "To extend your discount to future months, you'll need to:",
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.HEADING_3,
      },
      {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Make ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: '6+ purchases ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [],
                    value: 'a month with your SumUp Mastercard',
                    nodeType: 'text',
                  },
                ],
                nodeType: BLOCKS.PARAGRAPH,
              },
            ],
            nodeType: BLOCKS.LIST_ITEM,
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Each purchase should be worth at least ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: '£10',
                    nodeType: 'text',
                  },
                ],
                nodeType: BLOCKS.PARAGRAPH,
              },
            ],
            nodeType: BLOCKS.LIST_ITEM,
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Both ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: 'online and in-store ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [],
                    value: 'purchases count',
                    nodeType: 'text',
                  },
                ],
                nodeType: BLOCKS.PARAGRAPH,
              },
            ],
            nodeType: BLOCKS.LIST_ITEM,
          },
        ],
        nodeType: BLOCKS.UL_LIST,
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.PARAGRAPH,
      },
    ],
    nodeType: BLOCKS.DOCUMENT,
  },
  descriptionModal: {
    title: 'Fee discount with the Business Account',
    description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'If you choose to receive your sales in a free SumUp Business Account, we’ll lower your card reader fee by 12%.',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'You can extend your discount into future months by following the steps below.',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'What’s the lower fee?',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.HEADING_3,
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'To help your business grow, you’ll pay just 1.49% per card reader transaction.',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    requirements: {
      title: 'To extend your lower fee:',
      items: [
        {
          name: 'Business Account: 6 purchases a month',
          title: {
            nodeType: BLOCKS.DOCUMENT,
            data: {},
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'Make at least ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: '6 purchases a month ',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'with your SumUp Card',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
          icon: {
            imageSrc:
              'https://images.ctfassets.net/lj0z7qfa1yi0/p61mYx8MidUstJqyFXJyh/c3be13c7da5c36656e576a930dd1a1e3/Purchases.svg',
            imageAltText: 'To extend your lower fee',
          },
        },
        {
          name: 'Business Account: Purchase amount limit',
          title: {
            nodeType: BLOCKS.DOCUMENT,
            data: {},
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'Each purchase should be worth ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'at least £10',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    data: {},
                  },
                ],
              },
            ],
          },
          icon: {
            imageSrc:
              'https://images.ctfassets.net/lj0z7qfa1yi0/7hxyD3GXZPWNCWOA21J3QW/7b419097bf6285bfae984495b12b6a3b/10-euro.svg',
            imageAltText: 'Purchase amount limit',
          },
        },
        {
          name: 'Business Account: online and in-store purchases',
          title: {
            nodeType: BLOCKS.DOCUMENT,
            data: {},
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'Both ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'online and in-store ',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'purchases count',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
          icon: {
            imageSrc:
              'https://images.ctfassets.net/lj0z7qfa1yi0/Rih8VirmsqyEbeLzGopUj/fc4445ca03ae332b7463122ed55d9474/Shopping-bags.svg',
            imageAltText: 'Online and in-store purchases',
          },
        },
      ],
    },
  },
  productSelector: {
    name: 'SumUp Business Account',
    title: 'SumUp Business Account',
    badgeText: 'Better value',
    description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Card reader fee',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    productBenefits: [
      {
        name: 'Business Account: Free current account',
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Free current account',
                  nodeType: 'text',
                },
              ],
              nodeType: BLOCKS.PARAGRAPH,
            },
          ],
          nodeType: BLOCKS.DOCUMENT,
        },
        icon: {
          imageSrc:
            'https://images.ctfassets.net/lj0z7qfa1yi0/2VrCWYKZt4lUqjMzWACrS9/51b02504d8263c0a67dc423a2ae3206a/Vector.svg',
          imageAltText: 'Free current account',
        },
      },
      {
        name: 'Business Account: Free business Mastercard',
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Free business Mastercard',
                  nodeType: 'text',
                },
              ],
              nodeType: BLOCKS.PARAGRAPH,
            },
          ],
          nodeType: BLOCKS.DOCUMENT,
        },
        icon: {
          imageSrc:
            'https://images.ctfassets.net/lj0z7qfa1yi0/2VrCWYKZt4lUqjMzWACrS9/51b02504d8263c0a67dc423a2ae3206a/Vector.svg',
          imageAltText: 'Free business Mastercard',
        },
      },
      {
        name: 'Next-day access to sales - even on weekends and holidays',
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Next-day access to sales - even ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: 'on weekends and holidays',
                  nodeType: 'text',
                },
              ],
              nodeType: BLOCKS.PARAGRAPH,
            },
          ],
          nodeType: BLOCKS.DOCUMENT,
        },
        icon: {
          imageSrc:
            'https://images.ctfassets.net/lj0z7qfa1yi0/2VrCWYKZt4lUqjMzWACrS9/51b02504d8263c0a67dc423a2ae3206a/Vector.svg',
          imageAltText:
            'Next-day access to sales - even on weekends and holidays',
        },
      },
    ],
  },
  selectProductCtaLabel: 'Go to cart',
  transactionFee: '1.49%',
};

const upsellProductStaging: UpsellProduct = {
  id: createCFProductId('uTABq1d7g4XGTZmNtao4T'),
  lineItemType: 'sku',
  code: createSKUCode('BA2023001'),
  name: 'Business Account - BA2023001',
  title: 'Business Account',
  trackingId: 'offer.business_account',
  shortDescription: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: 'Select your preferred payment account',
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.HEADING_2,
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value:
              "By selecting a free Business Account, you'll get a 12% discount on your card reader fee, which you can extend for months just by using your SumUp Card.",
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.PARAGRAPH,
      },
    ],
    nodeType: BLOCKS.DOCUMENT,
  },
  descriptionCTA: 'See discount details',
  description: {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks: [
              {
                type: 'bold',
              },
            ],
            value: "To extend your discount to future months, you'll need to:",
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.HEADING_3,
      },
      {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Make ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: '6+ purchases ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [],
                    value: 'a month with your SumUp Mastercard',
                    nodeType: 'text',
                  },
                ],
                nodeType: BLOCKS.PARAGRAPH,
              },
            ],
            nodeType: BLOCKS.LIST_ITEM,
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Each purchase should be worth at least ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: '£10',
                    nodeType: 'text',
                  },
                ],
                nodeType: BLOCKS.PARAGRAPH,
              },
            ],
            nodeType: BLOCKS.LIST_ITEM,
          },
          {
            data: {},
            content: [
              {
                data: {},
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'Both ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    value: 'online and in-store ',
                    nodeType: 'text',
                  },
                  {
                    data: {},
                    marks: [],
                    value: 'purchases count',
                    nodeType: 'text',
                  },
                ],
                nodeType: BLOCKS.PARAGRAPH,
              },
            ],
            nodeType: BLOCKS.LIST_ITEM,
          },
        ],
        nodeType: BLOCKS.UL_LIST,
      },
      {
        data: {},
        content: [
          {
            data: {},
            marks: [],
            value: '',
            nodeType: 'text',
          },
        ],
        nodeType: BLOCKS.PARAGRAPH,
      },
    ],
    nodeType: BLOCKS.DOCUMENT,
  },
  descriptionModal: {
    title: 'Fee discount with the Business Account',
    description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'If you choose to receive your sales in a free SumUp Business Account, we’ll lower your card reader fee by 12%.',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'You can extend your discount into future months by following the steps below.',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'What’s the lower fee?',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.HEADING_3,
        },
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value:
                'To help your business grow, you’ll pay just 1.49% per card reader transaction.',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    requirements: {
      title: 'To extend your lower fee:',
      items: [
        {
          name: 'Business Account: 6 purchases a month',
          title: {
            nodeType: BLOCKS.DOCUMENT,
            data: {},
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'Make at least ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: '6 purchases a month ',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'with your SumUp Card',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
          icon: {
            imageSrc:
              'https://images.ctfassets.net/lj0z7qfa1yi0/mk0suva7b0AGBlRZB288q/9d4330ad5afe412a3523ba347b50e39d/Purchases.svg',
            imageAltText: '6 purchases a month',
          },
        },
        {
          name: 'Business Account: Purchase amount limit',
          title: {
            nodeType: BLOCKS.DOCUMENT,
            data: {},
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'Each purchase should be worth ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'at least £10',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    data: {},
                  },
                ],
              },
            ],
          },
          icon: {
            imageSrc:
              'https://images.ctfassets.net/lj0z7qfa1yi0/1ZM0dx752dPgrSPfGV3rhb/3159c64a968a2bc1c482ef7df7c0febf/10-euro.svg',
            imageAltText: 'Purchase amount limit',
          },
        },
        {
          name: 'Business Account: online and in-store purchases',
          title: {
            nodeType: BLOCKS.DOCUMENT,
            data: {},
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data: {},
                content: [
                  {
                    nodeType: 'text',
                    value: 'Both ',
                    marks: [],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'online and in-store ',
                    marks: [
                      {
                        type: 'bold',
                      },
                    ],
                    data: {},
                  },
                  {
                    nodeType: 'text',
                    value: 'purchases count',
                    marks: [],
                    data: {},
                  },
                ],
              },
            ],
          },
          icon: {
            imageSrc:
              'https://images.ctfassets.net/lj0z7qfa1yi0/1jUjudyCZH1Vr8pSCQaoTI/e2b04a3d44a9439b1b95127e8cce2183/Shopping-bags.svg',
            imageAltText: 'Online and in-store purchases',
          },
        },
      ],
    },
  },
  productSelector: {
    name: 'SumUp Business Account',
    title: 'SumUp Business Account',
    badgeText: 'Better value',
    description: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: 'Card reader fee',
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    productBenefits: [
      {
        name: 'Business Account: Free current account',
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Free current account',
                  nodeType: 'text',
                },
              ],
              nodeType: BLOCKS.PARAGRAPH,
            },
          ],
          nodeType: BLOCKS.DOCUMENT,
        },
        icon: {
          imageSrc:
            'https://images.ctfassets.net/lj0z7qfa1yi0/6fEGM5Nr2Ki2VKx3x4K037/37e4913a907d33bfb2302be1ceec00ab/Vector.svg',
          imageAltText: 'Free current account',
        },
      },
      {
        name: 'Business Account: Free business Mastercard',
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Free business Mastercard',
                  nodeType: 'text',
                },
              ],
              nodeType: BLOCKS.PARAGRAPH,
            },
          ],
          nodeType: BLOCKS.DOCUMENT,
        },
        icon: {
          imageSrc:
            'https://images.ctfassets.net/lj0z7qfa1yi0/6fEGM5Nr2Ki2VKx3x4K037/37e4913a907d33bfb2302be1ceec00ab/Vector.svg',
          imageAltText: 'Free business Mastercard',
        },
      },
      {
        name: 'Business Account: Access to sales in 1 day',
        title: {
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: 'Access to sales in ',
                  nodeType: 'text',
                },
                {
                  data: {},
                  marks: [
                    {
                      type: 'bold',
                    },
                  ],
                  value: '1 day',
                  nodeType: 'text',
                },
              ],
              nodeType: BLOCKS.PARAGRAPH,
            },
          ],
          nodeType: BLOCKS.DOCUMENT,
        },
        icon: {
          imageSrc:
            'https://images.ctfassets.net/lj0z7qfa1yi0/6fEGM5Nr2Ki2VKx3x4K037/37e4913a907d33bfb2302be1ceec00ab/Vector.svg',
          imageAltText: 'Access to sales in 1 day',
        },
      },
    ],
  },
  selectProductCtaLabel: 'Go to cart',
  transactionFee: '1.49%',
};

export const upsellProduct = (): UpsellProduct => {
  const IS_CF_MASTER = process.env.CONTENTFUL_ENVIRONMENT === 'master';

  if (IS_CF_MASTER) {
    return upsellProductMaster;
  }
  return upsellProductStaging;
};
